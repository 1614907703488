import type { ILoggerBase } from '@inwink/logging/logging/basetypes';
import type { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import type { States } from '../../../../services/services';
import type { IEventRequests } from '../../../../services/apiaccessprovider.definition';
import { getDate } from '../../../../data/syncutils';

function myleads(eventRequests: IEventRequests, exhibitorId, lastUpdate?: string) {
    let filters;
    if (lastUpdate) {
        filters = { lastUpdate: { $gt: getDate(moment(lastUpdate), false)}};
    }
    return import("../../../../api/front/datasource").then((mod) => {
        return mod.getFrontEntityDataSource<Entities.IExhibitorScan>(
            eventRequests.apiFront,
            exhibitorId ? `exhibitor/${exhibitorId}/scan` : `scan`, "ExhibitorScan"
        ).getAll({
            selects: {
                $all: true,
                person: {
                    $all: true
                }
            },
            order: [{ by: "lastUpdate" }],
            filters: filters
        });
    });
}

export function syncLeads(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;
    const entityName = "leads";
    const userData = uData;
    const user = userData.userDetail.data[0];

    if (user) {
        const allowRegisteredScan = (eventconf.registered && eventconf.registered.allowRegisteredScan)
            || (eventconf.userPresence && eventconf.userPresence.useBadges);

        if ((user.exhibitorAccounts && user.exhibitorAccounts.length) || allowRegisteredScan) {
            const lastsync = userData.lastsyncs.data.find((s) => s.key === entityName);
            const newDtSync = new Date();
            let dtSync;
            if (lastsync) {
                if (typeof lastsync.date === "string") {
                    dtSync = new Date(lastsync.date as string);
                } else {
                    dtSync = lastsync.date;
                }
            }

            const endSync = () => {
                let lastsync2 = userData.lastsyncs.data.find((s) => s.key === entityName);
                const insertLastSync = !lastsync2;
                if (!lastsync2) {
                    lastsync2 = {
                        date: newDtSync,
                        key: entityName
                    } as any;
                } else {
                    lastsync2.date = newDtSync;
                }

                if (insertLastSync) {
                    userData.lastsyncs.insert(lastsync2);
                } else {
                    userData.lastsyncs.update(lastsync2);
                }
            };

            if (force) {
                dtSync = null;
                userData.userleads.removeWhere(() => true);
            }

            let promises = [];

            if (user.exhibitorAccounts && user.exhibitorAccounts.length > 0) {
                promises = user.exhibitorAccounts.map((ea) => {
                    return myleads(eventRequests, ea.exhibitorId, dtSync).then((res) => {
                        const leads = res && res.data;
                        hasData = hasData || (leads && leads.length > 0);
                        syncUserLeads(eventconf, userData, leads);
                    }).then(null, (err) => {
                        console.error("Error synchronizing leads for " + ea.exhibitorId, err);
                    });
                });
            }

            if (allowRegisteredScan) {
                // ajout des scans de la personne
                promises.push(myleads(eventRequests, null, dtSync).then((res) => {
                    const leads = res && res.data;
                    hasData = hasData || (leads && leads.length > 0);
                    syncUserLeads(eventconf, userData, leads);
                }).then(null, (err) => {
                    console.error("Error synchronizing my leads", err);
                }));
            }

            return Promise.all(promises).then(() => {
                endSync();
                if (hasData) {
                    userData.updates.userleads = new Date().toISOString();
                }
                return hasData;
            });
        }
    }

    return Promise.resolve(false);
}

function syncUserLeads(eventconf: Entities.IEventDetailConfiguration,
    userData: States.IPersonDataStore, leads: Entities.IExhibitorScan[]) {
    if (leads && leads.length) {
        leads.forEach((lead) => {
            const l = Object.assign({}, lead, {
                exhibitorId: lead.exhibitorId,
                id: lead.id,
                scanDate: lead.scanDate,
                person: lead.person
            }) as any;
            const existing = userData.userleads.data.filter((u) => u.id === l.id)[0];
            if (!existing) {
                userData.userleads.insert(l);
            } else {
                l.$loki = (existing as any).$loki;
                l.meta = (existing as any).meta;
                userData.userleads.update(l);
            }
        });
    }
}
