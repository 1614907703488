import type { ILoggerBase } from '@inwink/logging/logging/basetypes';
import type { Entities } from '@inwink/entities/entities';
import * as moment from 'moment';
import type { States } from '../../../../services/services';
import { syncEntity, getDate } from '../../../../data/syncutils';
import type { IInwinkEntityV3QueryOptions } from '../../../../api/front';
import type { IEventRequests } from '../../../../services/apiaccessprovider.definition';

function getMeetings(eventRequests: IEventRequests, fromDate) {
    return import("../../../../api/front/datasource").then((mod) => {
        const ds = mod.getFrontEntityDataSource<Entities.IContactRequest>(eventRequests.apiFront, `meeting`, null);

        let filters = null;
        if (fromDate) {
            filters = { validFrom: { $gt: getDate(moment(fromDate), true)}};
        }
        const options: IInwinkEntityV3QueryOptions = {};
        options.selects = {
            $all: true,
            meetingPersons: {
                $all: true,
                exhibitorAccount: {
                    $all: true
                },
                person: {
                    $all: true
                }
            },
            timeslots: {
                $all: true,
                room: {
                    $all: true
                }
            }
        };
        options.filters = filters;
        options.order = [{ by: "validFrom" }];
        return ds.query(options, []).then((res) => res && res.data);
    });
}

export function syncUserMeeting(eventRequests: IEventRequests, logger: ILoggerBase,
    eventconf: Entities.IEventDetailConfiguration, userData: States.IPersonDataStore, force: boolean, trackProgress?) {
    let hasData = false;

    return syncEntity<Entities.IMeeting>({
        requestMgr: eventRequests.apiFront,
        logger,
        dataStore: userData,
        force,
        apiCallProvider: (lastsync) => {
            const syncdate = lastsync ? moment(lastsync).utc().toISOString() : null;

            return getMeetings(eventRequests, syncdate);
        },
        collectionname: "meetings",
        itemkey: (r, s) => {
            return r.id === s.id;
        },
        itemcallback: () => {
            hasData = true;
        },
        trackProgress
    }).then(() => {
        return hasData;
    });
}
