import { logging } from '@inwink/logging';
import { syncUserModule } from '@@event/features/user';
import { syncLeads } from '@@event/sync/usersync/leads';
import { syncUserMeeting } from '@@event/sync/usersync/meeting';
import { States } from '@@services/services';

const logger = logging.getLogger("Data");

export const userSyncActions = {
    dataChanged(userData?: States.IPersonDataStore) {
        return (dispatch) => {
            dispatch({ type: "USER_DATACHANGED", payload: { userData } });
        };
    },

    lightSyncCurrentUser(isRealtime: boolean, forcesync = false) {
        return (dispatch, getState: () => States.IAppState) => {
            const storedata = getState();
            if (storedata && storedata.user && storedata.user.currentUser) {
                return syncUserModule().then((mod) => {
                    return mod.lightSync(
                        storedata.event.requestManagers,
                        storedata.user.currentUser.data,
                        forcesync,
                        isRealtime,
                        dispatch,
                        getState
                    ).then((hasData) => {
                        if (hasData) { userSyncActions.dataChanged()(dispatch); }
                    }, (err) => {
                        logger.error("error in user light sync", err);
                    });
                });
            }
        };
    },

    syncDiscussionThreads(isRealtime: boolean, forcesync = false) {
        return (dispatch, getState: () => States.IAppState) => {
            const storedata = getState();
            if (storedata && storedata.user && storedata.user.currentUser) {
                return syncUserModule().then((mod) => {
                    return mod.syncDiscussions(
                        storedata.event.requestManagers,
                        storedata.user.currentUser.data,
                        forcesync,
                        isRealtime,
                        dispatch,
                        getState
                    ).then((hasData) => {
                        if (hasData) { userSyncActions.dataChanged()(dispatch); }
                    }, (err) => {
                        logger.error("error in user light sync", err);
                    });
                });
            }
        };
    },

    syncCurrentUser(forcesync = false) {
        return (dispatch, getState: () => States.IAppState) : Promise<boolean> => {
            const storedata = getState();
            if (storedata && storedata.user && storedata.user.currentUser) {
                return syncUserModule().then((mod) => {
                    return mod.userSync(
                        storedata.event.requestManagers,
                        storedata.user.currentUser.data,
                        forcesync,
                        dispatch,
                        getState
                    ).then((hasData) => {
                        // if (hasData)
                        //     actions.dataChanged()(dispatch);
                        return hasData;
                    }, (err) => {
                        logger.error("error syncing user data", err);
                    });
                });
            }

            return Promise.resolve(false);
        };
    },

    syncCurrentUserLeads(forcesync = false) {
        return (dispatch, getState: () => States.IAppState) => {
            const storedata = getState();
            if (storedata && storedata.user && storedata.user.currentUser) {
                const eventconf = storedata.event && storedata.event.detail && storedata.event.detail.configuration;
                return syncLeads(
                    storedata.event.requestManagers,
                    logger,
                    eventconf,
                    storedata.user.currentUser.data,
                    forcesync
                ).then((hasData) => {
                    if (hasData) {
                        userSyncActions.dataChanged()(dispatch);
                        storedata.user.currentUser.data.save();
                    }
                }, (err) => {
                    logger.error("error syncing user leads", err);
                });
            }
        };
    },

    syncCurrentUserMeetings(forcesync = false) {
        return (dispatch, getState: () => States.IAppState) => {
            const storedata = getState();
            if (storedata && storedata.user && storedata.user.currentUser) {
                const eventconf = storedata.event && storedata.event.detail && storedata.event.detail.configuration;
                return syncUserMeeting(
                    storedata.event.requestManagers,
                    logger,
                    eventconf,
                    storedata.user.currentUser.data,
                    forcesync
                ).then((hasData) => {
                    if (hasData) {
                        userSyncActions.dataChanged()(dispatch);
                        storedata.user.currentUser.data.save();
                    }
                }, (err) => {
                    logger.error("error syncing meetings", err);
                });
            }
        };
    },
};
